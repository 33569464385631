/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  /* transform: scale(1.08); */
}

.fp-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f8f8f8ad;
}

.fp-container .fp-loader {
  top: 30%;
  left: 48%;
  z-index: 1000;
  position: absolute;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  font-family: Roboto, sans-serif;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
}

.progress-indicator.available {
  opacity: 0;
}

.progress-indicator {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 1;
  pointer-events: none;
  -webkit-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  -moz-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  z-index: 9999;
}

.insp-logo-frame {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-animation: fadein 436ms;
  -moz-animation: fadein 436ms;
  animation: fadein 436ms;
  height: 98%;
  animation-name: stretch;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-fill-mode: none;
}

.insp-logo-frame-img {
  width: 112px;
  height: 112px;
  -webkit-align-self: center;
  -moz-align-self: center;
  align-self: center;
  border-radius: 50%;
}

.progress-indicator-head {
  background-color: #c6dafc;
  height: 4px;
  overflow: hidden;
  position: relative;
}

.progress-indicator .first-indicator,
.progress-indicator .second-indicator {
  background-color: rgb(218, 132, 62);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
}

.progress-indicator .first-indicator {
  -webkit-animation: first-indicator 2s linear infinite;
  -moz-animation: first-indicator 2s linear infinite;
  animation: first-indicator 2s linear infinite;
}

.progress-indicator .second-indicator {
  -webkit-animation: second-indicator 2s linear infinite;
  -moz-animation: second-indicator 2s linear infinite;
  animation: second-indicator 2s linear infinite;
}

.progress-indicator .insp-logo {
  animation: App-logo-spin infinite 20s linear;
  border-radius: 50%;
  -webkit-align-self: center;
  -moz-align-self: center;
  align-self: center;
}

@keyframes stretch {
  0% {
    transform: scale(0.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes first-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }

  25% {
    transform: translate(0) scaleX(0.5);
  }

  50% {
    transform: translate(25%) scaleX(0.75);
  }

  75% {
    transform: translate(100%) scaleX(0);
  }

  100% {
    transform: translate(100%) scaleX(0);
  }
}

@keyframes second-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }

  60% {
    transform: translate(0) scaleX(0);
  }

  80% {
    transform: translate(0) scaleX(0.6);
  }

  100% {
    transform: translate(100%) scaleX(0.1);
  }
}

.gallary {
  /*-webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;*/
  margin: 0px 12px;
}

.gallary .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
}

.swal2-title {
  padding: 0px 0px 0px 0px !important;
}

.gallary .pics_container {
  margin-bottom: 12px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 5px 5px 5px black;
  border-radius: 5px;
}

.gallary .pics img {
  -webkit-transition: transform 2s ease-in-out;
  transition: transform 2s ease-in-out;
}

.gallary .pics:hover {
  /* filter: opacity(.8); */
  transform: scale(1.1);
}

@media (max-width: 991px) {
  .gallary {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-width: 50%;
    -moz-column-width: 50%;
    column-width: 50%;
  }
}

@media (max-width: 480px) {
  .gallary {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}

/*model*/
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: black;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  max-width: 100%;

  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  margin: 0 auto;
}

.model.open svg {
  position: fixed;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
}

/* discount-model  */
.discount-model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, .7);
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 1s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.discount-model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

 /* body{
  height: 100%;
  overflow: hidden;
} */

.discount-model img {
  max-width: 100%;

  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  margin: 0 auto;
}

.discount-model.open svg {
  position: fixed;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
}


.userIcon {
  padding: 10px;
  border-radius: 8px;
  background: #f9f9f9;
  box-shadow: 0px 0px 2px 1px black;
  cursor: pointer;
}

svg.svg-inline--fa.fa-circle-user {
  margin-left: 15px !important;
  font-size: 20px;
  color: #4a4a4a;
}

svg.svg-inline--fa.fa-bars {
  font-size: 15px;
  color: #4a4a4a;
}

.adminLinks {
  display: grid;
}

span.pagination {
  padding: 0 8px 0 8px;
  cursor: pointer;
}

img.productImage {
  float: left;
  padding: 3px;
  width: 70px;
}

button.addProduct {
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  background: #dedfdf;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}

.animated-background {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}


::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #D9D9D9;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #f3c811; 
  border-radius: 10px;
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffc107; 
}
::-webkit-scrollbar-corner {
  background-color: transparent;
 }

.psv-autorotate-button {
  position: absolute !important;
  top: 50px !important;
  width: 25px !important;
  height: 25px !important;
  padding: 0px !important;
  right: 15px !important;
  background-color:black !important;
  border-radius: 50px;
  border: 1px solid white;
}

.psv-autorotate-button svg {
  padding: 5px;
  width: 15px !important;
  height: 15px !important;
  color: white;
  cursor: pointer;
}

.psv-fullscreen-button {
  position: absolute !important;
  top: 90px !important;
  width: 25px !important;
  height: 25px !important;
  padding: 0px !important;
  right: 15px !important;
  background-color:black !important;
  border-radius: 50px;
  border: 1px solid white;
}

.psv-fullscreen-button svg {
  padding: 5px;
  width: 15px !important;
  height: 15px !important;
  color: white;
  cursor: pointer;
}

.psv-menu-button {
  position: absolute !important;
  top: 110px !important;
  width: 25px !important;
  height: 25px !important;
  padding: 0px !important;
  right: 15px !important;
  background-color:black !important;
  border-radius: 50px;
  border: 1px solid white;
}

.psv-menu-button svg {
  padding: 5px;
  width: 15px !important;
  height: 15px !important;
  color: white;
  cursor: pointer;
}


.psv-zoom-button {
  display: none !important;
  position: absolute !important;
  top: 110px !important;
  right: 10px !important;
}

.psv-zoom-range {
  display: none !important;
}

.psv-navbar {
  top: 0 !important;
  left: "100vh" !important;
  right: 0 !important;
  z-index: 10 !important;
  background: none !important;
  height: "0vh" !important;
  width: "100px" !important;
} 

.indicators {
  gap:2px;
}

.indicator.active {
  background: #f3c811;
}